var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { rounded: "lg" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-alert",
            {
              attrs: {
                color: "primary darken-2",
                border: "top",
                outlined: "",
                icon: "mdi-alert",
                prominent: ""
              }
            },
            [
              _c("h3", [_vm._v("Request not authorized.")]),
              _c("p", { staticClass: "mt-4" }, [
                _vm._v(
                  " You do not have the necessary permissions to access this link. If you beleive this is in error, please contact your administrator. "
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }